<template>
  <table class="w-full table-fixed">
    <thead>
      <tr class="bg-gray-100 border-b border-gray-200 text-xs">
        <th class="text-left">
          <a
            class="px-5 py-1 block w-full cursor-pointer hover:bg-gray-200"
            @click="changeSort('number')"
            >Number
            <ChevronDownIcon
              class="inline-block h-4 w-4 text-green"
              v-if="sort == 'number' && sortDir == 'desc'"
            />
            <ChevronUpIcon
              class="inline-block h-4 w-4 text-green"
              v-if="sort == 'number' && sortDir == 'asc'"
            />
          </a>
        </th>
        <th class="text-left">
          <a
            class="px-5 py-1 block w-full cursor-pointer hover:bg-gray-200"
            @click="changeSort('name')"
            >Name
            <ChevronDownIcon
              class="inline-block h-4 w-4 text-green"
              v-if="sort == 'name' && sortDir == 'desc'"
            />
            <ChevronUpIcon
              class="inline-block h-4 w-4 text-green"
              v-if="sort == 'name' && sortDir == 'asc'"
            />
          </a>
        </th>
        <th class="text-left w-3/12">
          <a
            class="px-5 py-1 block w-full cursor-pointer hover:bg-gray-200"
            @click="changeSort('company')"
            >Company
            <ChevronDownIcon
              class="inline-block h-4 w-4 text-green"
              v-if="sort == 'company' && sortDir == 'desc'"
            />
            <ChevronUpIcon
              class="inline-block h-4 w-4 text-green"
              v-if="sort == 'company' && sortDir == 'asc'"
            />
          </a>
        </th>
        <th class="text-left">
          <a
            class="px-5 py-1 block w-full cursor-pointer hover:bg-gray-200"
            @click="changeSort('potential_value')"
            >Potential Value
            <ChevronDownIcon
              class="inline-block h-4 w-4 text-green"
              v-if="sort == 'potential_value' && sortDir == 'desc'"
            />
            <ChevronUpIcon
              class="inline-block h-4 w-4 text-green"
              v-if="sort == 'potential_value' && sortDir == 'asc'"
            />
          </a>
        </th>
        <th class="text-left">
          <a
            class="px-5 py-1 block w-full cursor-pointer hover:bg-gray-200"
            @click="changeSort('shipping_status')"
            >Shipping Status
            <ChevronDownIcon
              class="inline-block h-4 w-4 text-green"
              v-if="sort == 'shipping_status' && sortDir == 'desc'"
            />
            <ChevronUpIcon
              class="inline-block h-4 w-4 text-green"
              v-if="sort == 'shipping_status' && sortDir == 'asc'"
            />
          </a>
        </th>
        <th class="text-left">
          <a
            class="px-5 py-1 block w-full cursor-pointer hover:bg-gray-200"
            @click="changeSort('date_shipped')"
            >Date Shipped
            <ChevronDownIcon
              class="inline-block h-4 w-4 text-green"
              v-if="sort == 'date_shipped' && sortDir == 'desc'"
            />
            <ChevronUpIcon
              class="inline-block h-4 w-4 text-green"
              v-if="sort == 'date_shipped' && sortDir == 'asc'"
            />
          </a>
        </th>
        <th class="text-left">
          <a
            class="px-5 py-1 block w-full cursor-pointer hover:bg-gray-200"
            @click="changeSort('sales_status')"
            >Sales Status
            <ChevronDownIcon
              class="inline-block h-4 w-4 text-green"
              v-if="sort == 'sales_status' && sortDir == 'desc'"
            />
            <ChevronUpIcon
              class="inline-block h-4 w-4 text-green"
              v-if="sort == 'sales_status' && sortDir == 'asc'"
            />
          </a>
        </th>
        <th class="text-left">
          <a
            class="px-5 py-1 block w-full cursor-pointer hover:bg-gray-200"
            @click="changeSort('created_at')"
            >Created
            <ChevronDownIcon
              class="inline-block h-4 w-4 text-green"
              v-if="sort == 'created_at' && sortDir == 'desc'"
            />
            <ChevronUpIcon
              class="inline-block h-4 w-4 text-green"
              v-if="sort == 'created_at' && sortDir == 'asc'"
            />
          </a>
        </th>
        <th class="text-left">
          <a
            class="px-5 py-1 block w-full cursor-pointer hover:bg-gray-200"
            @click="changeSort('updated_at')"
            >Updated
            <ChevronDownIcon
              class="inline-block h-4 w-4 text-green"
              v-if="sort == 'updated_at' && sortDir == 'desc'"
            />
            <ChevronUpIcon
              class="inline-block h-4 w-4 text-green"
              v-if="sort == 'updated_at' && sortDir == 'asc'"
            />
          </a>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        class="border-b border-gray-200 text-sm"
        v-for="sample in allSamples"
        v-bind:key="sample.id"
      >
        <td class="px-5 py-2">
          <router-link
            class="text-green hover:underline"
            :to="`/samples/${sample.id}`"
            >{{ sample.number }}</router-link
          >
        </td>
        <td class="px-5 py-2">{{ sample.name }}</td>
        <td class="px-5 py-2">{{ sample.company }}</td>
        <td class="px-5 py-2">
          <span v-if="sample.potential_value"
            >${{
              Number(sample.potential_value).toLocaleString(undefined, {
                minimumFractionDigits: 2,
              })
            }}</span
          >
        </td>
        <td class="px-5 py-2">{{ sample.shipping_status_text }}</td>
        <td class="px-5 py-2">{{ sample.date_shipped }}</td>
        <td class="px-5 py-2">{{ sample.sales_status_text }}</td>
        <td class="px-5 py-2">{{ parseDate(sample.created_at) }}</td>
        <td class="px-5 py-2">{{ parseDate(sample.updated_at) }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/vue/solid";

export default {
  components: { ChevronDownIcon, ChevronUpIcon },
  data: () => {
    return {
      sort: "updated_at",
      sortDir: "desc",
    };
  },

  props: {
    samples: Array,
  },

  computed: {
    allSamples() {
      let samples = this.samples;
      return samples.sort(this.compare);
    },
  },

  methods: {
    parseDate(date) {
      let d = new Date(date);
      return d.toLocaleDateString();
    },

    changeSort(sort = null) {
      if (!this.allSamples) return;
      if (this.sort === sort) {
        this.sortDir = this.sortDir == "asc" ? "desc" : "asc";
      } else {
        this.sort = sort;
        this.sortDir = "desc";
      }
      this.allSamples.sort(this.compare);
    },

    compare(a, b) {
      let aVal = a[this.sort];
      let bVal = b[this.sort];
      if (!isNaN(aVal) || !isNaN(bVal)) {
        aVal = aVal ? parseFloat(aVal) : 0;
        bVal = bVal ? parseFloat(bVal) : 0;
      }
      if (aVal > bVal) return this.sortDir == "asc" ? 1 : -1;
      if (aVal < bVal) return this.sortDir == "asc" ? -1 : 1;
      return 0;
    },
  },
};
</script>
