<template>
  <div class="bg-gray-200">
    <div class="container mx-auto grid grid-cols-2 w-full">
      <div class="py-2 text-md">
        <router-link to="/" class="text-green hover:underline"
          >Dashboard</router-link
        >
        <span class="text-gray-400"> / </span>
        <span class="font-bold">Sample Requests</span>
      </div>
      <span class="text-right block text-md">
        <a
          class="cursor-pointer font-bold inline-block h-full py-2 text-green hover:underline"
          v-if="!archived"
          @click="loadSamples(true)"
          >Show Archived</a
        >
        <a
          class="cursor-pointer inline-block h-full py-2 text-green hover:underline"
          v-if="archived"
          @click="loadSamples(false)"
          >Show Active</a
        >
        <span class="inline-block p-2 text-gray-400"> | </span>
        <router-link
          class="cursor-pointer font-bold inline-block h-full py-2 text-green hover:underline"
          to="/samples/create"
          >Create Sample Request</router-link
        >
      </span>
    </div>
  </div>
  <div class="container mx-auto py-5">
    <div class="mb-5" v-if="sales_funnel.length > 0">
      <h3 class="text-lg font-medium leading-6 text-gray-900">
        Sales Funnel. Last 365 days.
      </h3>
      <dl
        class="mt-5 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-6 md:divide-y-0 md:divide-x"
      >
        <template v-for="item in sales_funnel" :key="item.sales_status">
          <div class="px-4 py-5 sm:p-6">
            <dt class="text-base font-normal text-gray-900">
              {{ item.sales_status_description }}
            </dt>
            <dd
              class="mt-1 flex items-baseline justify-between md:block lg:flex"
            >
              <div
                class="flex items-baseline text-2xl font-semibold"
                :class="classesForSalesStatus(item)"
              >
                $ {{ item.potential_value }}
              </div>
            </dd>
          </div>
        </template>
      </dl>
    </div>

    <div class="shadow bg-gray-100 mb-5">
      <div class="px-5 py-2 border-b border-gray-200 bg-gray-50">
        <span class="font-bold">Sample Requests</span>
        <span class="block text-xs text-gray-400">Assigned to you</span>
      </div>
      <div v-if="userSamples.length > 0">
        <SamplesTable :samples="userSamples" />
      </div>
      <div v-if="userSamples.length == 0 && !loadingSamples">
        <span class="block text-sm italic px-5 py-2 text-gray-400"
          >No samples assigned to you</span
        >
      </div>
    </div>

    <div class="shadow bg-gray-100">
      <div class="px-5 py-2 border-b border-gray-200 bg-gray-50">
        <span class="font-bold">Sample Requests</span>
        <span class="block text-xs text-gray-400"
          >Assigned to someone in your company</span
        >
      </div>
      <div v-if="companySamples.length > 0">
        <SamplesTable :samples="companySamples" />
      </div>
      <div v-if="companySamples.length == 0 && !loadingSamples">
        <span class="block text-sm italic px-5 py-2 text-gray-400"
          >No samples assigned to your company</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SamplesTable from "@/components/SamplesTable.vue";
import axios from "axios";

export default {
  components: {
    SamplesTable,
  },

  data: () => {
    return {
      userSamples: [],
      companySamples: [],
      loadingSamples: true,
      archived: false,
      sales_funnel: [],
    };
  },

  computed: {
    ...mapGetters({
      samples: "samples/rows",
      user: "user",
    }),
  },

  created: function () {
    document.title = "MLE Rep Center | Samples";
    this.loadSamples();
    this.getSalesFunnel();
  },

  methods: {
    classesForSalesStatus(salesStatus) {
      if (salesStatus.sales_status === 4) {
        return "text-green-600";
      }

      if (salesStatus.sales_status === 5) {
        return "text-red-600";
      }

      return "text-gray-600";
    },

    async getSalesFunnel() {
      const resp = await axios.get(
        "api/rep-center/me/sample-requests/sales-funnel"
      );
      this.sales_funnel = resp.data.sales_funnel;
    },

    async loadSamples(archived = false) {
      this.archived = archived;
      this.loadingSamples = true;
      await this.$store.dispatch("samples/load", archived);
      this.parseSamples();
      this.loadingSamples = false;
    },
    parseSamples() {
      this.userSamples = [];
      this.companySamples = [];
      for (let s = 0; s < this.samples.length; s++) {
        if (this.isUserSample(this.samples[s])) {
          this.userSamples.push(this.samples[s]);
        } else {
          this.companySamples.push(this.samples[s]);
        }
      }
    },
    isUserSample(sample) {
      for (let r = 0; r < sample.rep_users.length; r++) {
        let rep = sample.rep_users[r];
        if (rep.id === this.user.id) {
          return true;
        }
      }
      return false;
    },
  },
};
</script>
